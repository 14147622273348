import { languagesDate } from '@/components/languageButton';
import { darkLogoUrl, lightLogoUrl } from '@/config';
import {
  getDarkLogoPath,
  getFaviconPath,
  getLanguages,
  getLightLogoPath,
  getPrimaryColor,
  getSecondaryColor,
  getThemes,
  getTitle,
} from '@zenlayer/portal-utils';
import { Theme, useTheme } from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import { useFavicon, useTitle } from 'ahooks';
import { useEffect, useRef, useState } from 'react';

const useLoadStaticResource = (url: string) => {
  const prevLinkRef = useRef<HTMLLinkElement | null>(null);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    if (!!prevLinkRef.current) {
      link.onload = () => {
        document.head.removeChild(prevLinkRef.current!);
        prevLinkRef.current = link;
      };
    } else {
      prevLinkRef.current = link;
    }

    document.head.appendChild(link);
  }, [url]);
};

function hexToRgbA(hex: string, opacity: string = '1') {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

const setRootStyleVar = (color: {
  primaryColor?: string;
  secondaryColor?: string;
}) => {
  const setColorOpt = (baseColor: string, key: string) => {
    const htmlStyle = getComputedStyle(
      document.querySelector('html') as HTMLHtmlElement
    );
    const htmlVarValue = htmlStyle.getPropertyValue(key);

    if (!!htmlVarValue) {
      const opacity = htmlVarValue?.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([0-9.]+)\)/
      )?.[4];

      document.body.style.setProperty(key, hexToRgbA(baseColor, opacity));
    }
  };

  if (color.primaryColor) {
    setColorOpt(color.primaryColor, '--zen-design-primary-high-color');
    setColorOpt(color.primaryColor, '--zen-design-primary-med-color');
    setColorOpt(color.primaryColor, '--zen-design-primary-low-color');
    setColorOpt(color.primaryColor, '--zen-design-primary-focusoutline-states');
    setColorOpt(color.primaryColor, '--zen-design-primary-pressedfill-states');
    setColorOpt(color.primaryColor, '--zen-design-primary-hover-states');
  }
  if (color.secondaryColor) {
    setColorOpt(color.secondaryColor, '--zen-design-secondary-high-color');
    setColorOpt(color.secondaryColor, '--zen-design-secondary-med-color');
    setColorOpt(color.secondaryColor, '--zen-design-secondary-low-color');
    setColorOpt(
      color.secondaryColor,
      '--zen-design-secondary-focusoutline-states'
    );
    setColorOpt(
      color.secondaryColor,
      '--zen-design-secondary-pressedfill-states'
    );
    setColorOpt(color.secondaryColor, '--zen-design-secondary-hover-states');
  }
};
export const useBrand = () => {
  const [theme, themeColors] = useTheme();
  const [theme_postMessage, setTheme_postMessage] = useState<Theme[]>();

  useTitle(getTitle());
  useFavicon(getFaviconPath());

  useEffect(() => {
    const handleMessage = function (e: MessageEvent<any>) {
      if (e.data.type === 'theme') {
        const value = e.data.value;
        setTheme_postMessage(value);
      } else if (e.data.type === 'primaryColor') {
        const value = e.data.value;
        window._console_info.consoleBranding.primaryColor = value;

        setRootStyleVar({ primaryColor: value });
      } else if (e.data.type === 'secondaryColor') {
        const value = e.data.value;
        window._console_info.consoleBranding.secondaryColor = value;

        setRootStyleVar({ secondaryColor: value });
      } else if (e.data.type === 'logoUrl_light') {
        const value = e.data.value;
        lightLogoUrl.setValue(value);
      } else if (e.data.type === 'logoUrl_dark') {
        const value = e.data.value;
        darkLogoUrl.setValue(value);
      } else if (e.data.type === 'languages') {
        const value = e.data.value;
        languagesDate.setValue(value);
      } else if (e.data.type === 'partnerCode') {
        const value = e.data.value;
        this.window._console_info.partnerCode = value;
      } else if (e.data.type === 'setConsoleInfo') {
        const value = e.data.value;
        window._console_info = value;
        setTheme_postMessage(getThemes());

        setRootStyleVar({
          primaryColor: getPrimaryColor(),
          secondaryColor: getSecondaryColor(),
        });
        lightLogoUrl.setValue(getLightLogoPath());
        darkLogoUrl.setValue(getDarkLogoPath());
        languagesDate.setValue(getLanguages());
      }
    };
    window.addEventListener('message', handleMessage, true);

    setRootStyleVar({
      primaryColor: getPrimaryColor(),
      secondaryColor: getSecondaryColor(),
    });

    return () => window.removeEventListener('message', handleMessage);
  }, [themeColors]);

  useLoadStaticResource(
    (__PRODUCTION__ ? window._zen_env_.public_path : '') +
      `/static/style/themes${__PRODUCTION__ ? `_${__VERSION__}` : ''}/${
        theme_postMessage || theme
      }/index.css`
  ); //缓存，加版本号
};
