import { ThemeAutoSwitchHOC } from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import consoleInfo from './config.json';
export {
  getCopywritingTag,
  getIsDefaultPartner,
  getIsTelinPartner,
  getIsChiefPartner,
  getIsHarmonyPartner,
  getPartnerCode,
  getThemes,
  getRemittanceContent,
  getApiDocsUrl,
  getContactUsSales,
  getContactUsSupport,
} from '@zenlayer/portal-utils';

import {
  getCopyrightInfo,
  getLightLogoPath,
  getDarkLogoPath,
  DateListener,
} from '@zenlayer/portal-utils';

export const getFooterCopyrightInfo = getCopyrightInfo;
export const getRegistrationContent = getCopyrightInfo;

if (!__PRODUCTION__) {
  window._console_info = consoleInfo as any;
}

export const lightLogoUrl = new DateListener<'LIGHTLOGOURL', string>(
  'LIGHTLOGOURL',
  undefined,
  getLightLogoPath()
);

export const darkLogoUrl = new DateListener<'DARKLOGOURL', string>(
  'DARKLOGOURL',
  undefined,
  getDarkLogoPath()
);

export const HasTextLogo = (props?: React.ImgHTMLAttributes<any>) => {
  const lightUrl = lightLogoUrl.useValueListener();
  const darkUrl = darkLogoUrl.useValueListener();

  return (
    <ThemeAutoSwitchHOC
      dark={<img {...props} src={darkUrl} />}
      light={<img {...props} src={lightUrl} />}
    />
  );
};
