import { DateListener, getLanguages } from '@zenlayer/portal-utils';
import { Button, Dropdown } from '@zenlayer/zen-design';
import { ButtonProps } from '@zenlayer/zen-design/esm/button';
import { MenuProps } from '@zenlayer/zen-design/esm/menu';
import t, { getLocale, Locales, setLocale } from '@zenlayer/zen-intl18';
import * as React from 'react';

export const languagesDate = new DateListener(
  'LANGUAGES',
  undefined,
  getLanguages()
);

export const useLanguages = () => {
  const languages = languagesDate.useValueListener();
  React.useEffect(() => {
    if (!languages.includes(getLocale())) {
      setLocale(languages[0]);
    }
  }, [languages]);
  return {
    languages,
    showLanguageButton: languages.length > 1,
  };
};

const LanguageButton: React.FC<ButtonProps> = (props) => {
  const currentLocale = getLocale();
  const text = {
    en: t('5I0FOGtYPA5m'),
    zh: t('9_BOf4jy0QMx'),
  }[currentLocale];

  const { languages } = useLanguages();
  const items = React.useMemo(() => {
    let _items: MenuProps['items'] = [];
    if (languages.includes('en')) {
      _items = [..._items, { key: 'en', label: 'English' }];
    }
    if (languages.includes('zh')) {
      _items = [..._items, { key: 'zh', label: '中文' }];
    }
    return _items;
  }, [languages]);
  return (
    <Dropdown
      menu={{
        items,
        selectedKeys: [currentLocale],
        onClick: ({ key }) => {
          key !== currentLocale && setLocale(key as Locales);
        },
      }}
    >
      <Button type="normal" size="s" textBaseColor {...props}>
        {text}
      </Button>
    </Dropdown>
  );
};

export default LanguageButton;
